import React from "react";
import PropTypes from "prop-types";

import PromptContext from "./Context";
import usePromptController from "./usePromptController";
import Prompt from "./Prompt";

const Provider = ({ children }) => {
  const [
    config,
    showInfo,
    showWarning,
    showError,
    showConfirmation,
    onClose,
    onExit,
  ] = usePromptController();
  const contextValue = React.useMemo(
    () => ({
      config,
      showInfo,
      showWarning,
      showError,
      showConfirmation,
      onClose,
    }),
    [config, showInfo, showWarning, showError, showConfirmation, onClose]
  );

  return (
    <PromptContext.Provider value={contextValue}>
      {children}
      <Prompt {...config} onClose={onClose} onExit={onExit} />
    </PromptContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;
