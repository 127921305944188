import React from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";

import Modal from "../Modal";
import Button from "../Button";
import { PROMPT_TYPE } from "../../constants";
import "./styles.scss";

const BasePromptPropTypes = {
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

const ConfirmationFooter = ({ onReject, onConfirm, onClose }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const onClickConfirm = React.useCallback(async () => {
    if (onConfirm) {
      setSubmitting(true);
      return Promise.resolve(onConfirm()).finally(() => {
        onClose();

        setSubmitting(false);
      });
    }
    onConfirm && onConfirm();
    onClose();
  }, [onClose, onConfirm, setSubmitting]);

  const onClickCancel = React.useCallback(() => {
    onReject && onReject();
    onClose();
  }, [onClose, onReject]);

  return (
    <Prompt.Footer>
      <Button variant="dark" onClick={onClickCancel} disabled={submitting}>
        Cancel
      </Button>
      <Button onClick={onClickConfirm}>
        {!submitting && "Confirm"}
        {submitting && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </Prompt.Footer>
  );
};

ConfirmationFooter.propTypes = BasePromptPropTypes;

const VariantFooter = ({ onClose }) => (
  <Modal.Footer className="justify-content-center">
    <Button onClick={onClose}>Ok</Button>
  </Modal.Footer>
);

VariantFooter.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const Prompt = ({
  type,
  footer,
  onClose,
  onConfirm,
  onReject,
  message,
  ...modalProps
}) => (
  <Modal
    size="sm"
    animation={false}
    dialogClassName="mydpd-prompt"
    {...modalProps}
    onHide={onClose}
  >
    <Modal.Body>{message}</Modal.Body>
    {type === PROMPT_TYPE.CONFIRMATION ? (
      <ConfirmationFooter
        onClose={onClose}
        onReject={onReject}
        onConfirm={onConfirm}
      />
    ) : (
      <VariantFooter onClose={onClose} />
    )}
  </Modal>
);

Prompt.Footer = Modal.Footer;

Prompt.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ...BasePromptPropTypes,
  ...Modal.propTypes,
};

export default Prompt;
