import React from "react";
import PropTypes from "prop-types";

import Header from "../Header";
import "./styles.scss";
import Footer from "./Footer";
import Content from "./Content";

export default function Layout({ children, header, footer }) {
  return (
    <div className="app-layout">
      <div className="app-header">{header}</div>
      <div className="app-content">{children}</div>
      <p className="app-footer text-center">{footer || <Footer />}</p>
    </div>
  );
}

Layout.Header = Header;
Layout.Content = Content;
Layout.Footer = Footer;

Layout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
};
