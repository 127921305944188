import React from "react";
import PropTypes from "prop-types";

import Spinner from "../../components/Spinner";
import { ErrorAlert } from "../../components/Error";
import LoaderError from "./LoaderError";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  completed: false,
  eventId: "",
};

class Loader extends React.Component {
  static propTypes = {
    loadFn: PropTypes.func,
    children: PropTypes.node,
    onLoadFailure: PropTypes.func,
    onLoadSuccess: PropTypes.func,
    ErrorComponent: PropTypes.elementType,
  };

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  completeLoad = (error, params = {}) => {
    this.setState(
      {
        isLoading: false,
        completed: true,
        error,
        ...params,
      },
      () => {
        this.fetchController = null;
      }
    );
  };

  loadFn = reload => {
    this.fetchController = new window.AbortController();
    const { loadFn } = this.props;
    !reload &&
      this.setState({
        isLoading: true,
      });

    return Promise.resolve(
      loadFn(this.props, { signal: this.fetchController.signal, reload })
    )
      .then(result => {
        this.props.onLoadSuccess(result);
        this.completeLoad(null, { data: result });
        return result;
      })
      .catch(e => {
        // only throw exception if not abort
        if (e.name !== "AbortError") {
          try {
            const eventId = this.props.onLoadFailure(e);
            this.completeLoad(e, {
              eventId,
            });
          } catch (e) {
            this.completeLoad(e);
            if (!(e instanceof LoaderError)) {
              // if unexpected error happens propagate to sentry
              throw e;
            }
          }
        }
      });
  };

  reloadFn = () => {
    return this.loadFn(true);
  };

  componentDidMount() {
    this.loadFn(false);
  }

  componentWillUnmount() {
    if (this.fetchController) {
      this.fetchController.abort();
    }
  }

  render() {
    const { isLoading, error, completed, eventId } = this.state;
    const { loadFn, children, ErrorComponent, ...props } = this.props;
    if (isLoading) {
      return <Spinner />;
    } else if (error) {
      return (
        <ErrorComponent
          error={error}
          {...this.props}
          eventId={eventId}
          reloadFn={this.loadFn}
        />
      );
    } else if (completed) {
      return React.cloneElement(children, {
        reloadFn: this.loadFn,
        ...props,
      });
    }
    return null;
  }
}

Loader.defaultProps = {
  onLoadSuccess: () => null,
  onLoadFailure: () => null,
  ErrorComponent: ErrorAlert,
};

export default Loader;
