import React from "react";
import classNames from "classnames";

import Table, { TablePropTypes } from "./Table";

const BorderlessTable = ({ className, ...props }) => (
  <Table
    {...props}
    borderless
    className={classNames("mydpd-table-borderless", className)}
  />
);

BorderlessTable.propTypes = TablePropTypes;

export default BorderlessTable;
