import React, { forwardRef } from "react";
import TinyPopover from "react-tiny-popover";

import "./styles.scss";

const Popover = (props, ref) => (
  <TinyPopover
    ref={ref}
    position="top"
    containerClassName="mydpd-popover"
    {...props}
  />
);

export default forwardRef(Popover);
