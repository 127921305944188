import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const SecondaryMenu = props => {
  const [activeIndex, setActiveIndex] = React.useState(props.activeIndex);
  return (
    <div className="nav-secondary-menu">
      {props.menuItems.map(({ href, name }, index) => (
        <a
          key={index}
          href={href}
          name={name}
          className={activeIndex === index ? "active" : undefined}
          onClick={() => setActiveIndex(index)}
        >
          {name}
        </a>
      ))}
    </div>
  );
};

SecondaryMenu.propTypes = {
  activeIndex: PropTypes.number,
  menuItems: PropTypes.array,
};

export default SecondaryMenu;
