import React from "react";
import PropTypes from "prop-types";

const NavLink = ({ href, name, className, ...restProps }) => (
  <a href={href} className={className} {...restProps}>
    {name}
  </a>
);

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NavLink;
