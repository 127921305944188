import React from "react";
import classNames from "classnames";

import Table, { TablePropTypes } from "./Table";

const BorderedTable = ({ className, ...props }) => (
  <Table
    {...props}
    bordered
    className={classNames("mydpd-table-bordered", className)}
  />
);

BorderedTable.propTypes = TablePropTypes;

export default BorderedTable;
