import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";
import "./styles.scss";

const Panel = ({ children, ...props }) => (
  <Card {...props} className={classNames("panel", props.className)}>
    {children}
  </Card>
);

Panel.Body = Body;
Panel.Header = Header;
Panel.Footer = Footer;

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Panel;
