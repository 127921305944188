import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import NavLink from "./NavLink";
import PrimaryMenu from "./PrimaryMenu";
import SecondaryMenu from "./SecondaryMenu";
import {
  ADDRESS_BOOK,
  COLLECTIONS,
  DELIVERIES,
  DEPOT_FINDER,
  HELP,
  HOME,
  LOGOUT,
  MY_DPD,
  MY_DPD_LOCAL,
  SHIPPING,
  SHOP_RETURNS,
} from "../../constants/messages";
import { THEME } from "../Theme/values";
import useMyDpdTheme from "../Theme/useMyDpdTheme";
import "./styles.scss";

const noop = () => null;

const Header = ({
  className,
  secondary,
  activeIndex,
  onPrimaryMenuSelect,
  onClickDpdLogo,
  onClickHelp,
  onClickLogOff,
}) => {
  const theme = useMyDpdTheme();
  return (
    <div className={classNames("nav-header", className)}>
      <div className="nav-logo">
        <a href={theme.dpdDomain} title={HOME} onClick={onClickDpdLogo}>
          <img src={theme.logoImg} width="96" height="40" alt="Site Logo" />
        </a>
      </div>
      <div className="nav-bar">
        <div className="utilities">
          <NavLink
            href="http://help.dpd.co.uk/mydpd/"
            name={HELP}
            onClick={onClickHelp}
          />
          <span> | </span>
          <NavLink
            href={`${theme.dpdDomain}/umslogon/public/logoff.do`}
            name={LOGOUT}
            onClick={onClickLogOff}
          />
        </div>
        <div className="nav-primary">
          <PrimaryMenu
            onSelect={onPrimaryMenuSelect}
            menuItems={getPrimaryMenuItems(theme)}
            activeIndex={activeIndex}
          />
        </div>
        <div className="nav-secondary">
          {Array.isArray(secondary) ? (
            <SecondaryMenu menuItems={secondary} />
          ) : (
            secondary
          )}
        </div>
      </div>
    </div>
  );
};

const getPrimaryMenuItems = theme => {
  return [
    {
      href: `${theme.dpdDomain}/umslogon/welcome.do`,
      name: theme.themeName === THEME.DPD ? MY_DPD : MY_DPD_LOCAL,
    },
    {
      href: `${theme.dpdDomain}/app/online.html#shipment/create`,
      name: SHIPPING,
    },
    { href: `${theme.dpdDomain}/collection`, name: COLLECTIONS },
    { href: `${theme.dpdDomain}/deliverysearch/#search`, name: DELIVERIES },
    {
      href: `${theme.dpdDomain}/app/online.html#returns/create`,
      name: SHOP_RETURNS,
    },
    { href: `${theme.dpdDomain}/app/addressbook.html`, name: ADDRESS_BOOK },
    {
      href: `${theme.dpdDomain}/apps/depotfinder/index.jsp`,
      name: DEPOT_FINDER,
    },
  ];
};

Header.defaultProps = {
  onClickDpdLogo: noop,
  onClickHelp: noop,
  onClickLogOff: noop,
  onPrimaryMenuSelect: noop,
};

Header.propTypes = {
  className: PropTypes.string,
  activeIndex: PropTypes.number,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  onClickDpdLogo: PropTypes.func,
  onClickHelp: PropTypes.func,
  onClickLogOff: PropTypes.func,
  onPrimaryMenuSelect: PropTypes.func,
};

export default Header;
