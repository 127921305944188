import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";

const renderHeaderRow = columns => {
  return (
    <thead>
      <tr>
        {columns.map(({ text, style }, index) => (
          <th key={index} style={style}>
            {text}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const renderCell = (
  { component: Component, render, dataIndex },
  rowData,
  rowIndex
) => {
  if (typeof Component === "function") {
    return <Component row={rowData} rowIndex={rowIndex} />;
  } else if (typeof render === "function") {
    return render(rowData, rowIndex);
  }
  return dataIndex ? rowData[dataIndex] : "";
};

const renderTableBody = (data, columns, onClickRow) => {
  return (
    <tbody>
      {data.map((row, i) => (
        <tr key={i} onClick={() => onClickRow(row)}>
          {columns.map((column, rowIndex) => (
            <td
              onClick={event => {
                column.onClick && column.onClick(event, row, rowIndex);
              }}
              key={column.geyKey ? column.geyKey(row) : rowIndex}
            >
              {renderCell(column, row, rowIndex)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const BsTable = ({ data, onClickRow, columns, className, ...restProps }) => (
  <Table responsive="sm" {...restProps} className={className}>
    {renderHeaderRow(columns)}
    {renderTableBody(data, columns, onClickRow)}
  </Table>
);

export const CellPropsTypes = {
  row: PropTypes.object,
  rowIndex: PropTypes.number,
};

export const TablePropTypes = (BsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      style: PropTypes.object,
      onClick: PropTypes.func,
      render: PropTypes.func,
      component: PropTypes.elementType,
    })
  ),
  onClickRow: PropTypes.func,
  className: PropTypes.string,
});

BsTable.defaultProps = {
  data: [],
  columns: [],
  onClickRow: () => null,
};

export default BsTable;
