import * as React from "react";
import { VARIANT } from "../../constants/variant";

const initialState = {
  show: false,
  type: null,
  message: null,
};

const usePromptController = () => {
  const [config, setConfig] = React.useState(initialState);

  const open = React.useCallback(
    options => {
      setConfig({
        show: true,
        ...options,
      });
    },
    [setConfig]
  );

  const onClose = React.useCallback(() => {
    setConfig({
      ...config,
      show: false,
    });
  }, [config, setConfig]);

  const onExit = React.useCallback(() => {
    setConfig(initialState);
  }, [setConfig]);

  const showError = React.useCallback(
    message => {
      open({
        message,
        variant: VARIANT.DANGER,
      });
    },
    [open]
  );
  const showWarning = React.useCallback(
    message => {
      open({
        message,
        variant: VARIANT.WARNING,
      });
    },
    [open]
  );

  const showInfo = React.useCallback(
    (message, onConfirm) => {
      open({
        message,
        type: VARIANT.INFO,
        onConfirm,
      });
    },
    [open]
  );

  const showConfirmation = React.useCallback(
    (message, onConfirm, onReject) => {
      open({
        message,
        type: "confirmation",
        onConfirm,
        onReject,
      });
    },
    [open]
  );

  return [
    config,
    showInfo,
    showWarning,
    showError,
    showConfirmation,
    onClose,
    onExit,
  ];
};

export default usePromptController;
