import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "../../../components/Button";
import "./styles.scss";

const from = (page, pageSize) => (page - 1) * pageSize + 1;
const to = (page, pageSize, totalCount) => {
  const toCandidate = page * pageSize;
  return toCandidate <= totalCount ? toCandidate : totalCount;
};

const Paginator = ({ page, pageSize, totalCount, onPrevious, onNext }) => {
  const totalCountDiff = Math.max(from(page, pageSize) - totalCount, 0);
  const pageDiff = totalCountDiff && Math.ceil(totalCountDiff / pageSize);

  return (
    <Container fluid>
      <Row className={classNames("bgGrey", "pagingInfoContainer")}>
        <Col>
          <span className="text_status">
            Results{" "}
            {`${from(page, pageSize) - totalCountDiff} to ${to(
              page,
              pageSize,
              totalCount
            )} of ${totalCount}`}
          </span>
        </Col>
      </Row>
      {totalCount > pageSize && (
        <Row className={classNames("bgGrey", "actionButtonsContainer")}>
          <Col>
            <Button
              onClick={() => onPrevious(page - 1 - pageDiff)}
              disabled={page <= 1}
            >
              Previous
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              onClick={() => onNext(page + 1 - pageDiff)}
              disabled={Math.ceil(totalCount / pageSize) <= page}
            >
              Next
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

Paginator.from = from;
Paginator.to = to;

Paginator.defaultProps = {
  onNext: () => null,
  onPrevious: () => null,
};

Paginator.propTypes = {
  page: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
};

export default Paginator;
