const MessageCodes = {
  400: "Oops, there was a problem completing your request",
  403: "Whoa there, you are not authorised to perform that request",
  404: "Oops, the page you are looking for has not been found",
  408: "This request took longer than expected, please try again",
  default: "Oops, something went wrong",
};

class LoaderError extends Error {
  constructor(error) {
    super();
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LoaderError);
    }
    this.name = "LoaderError";
    this.message =
      LoaderError.CODE_TO_MESSAGE[error.statusCode] ||
      LoaderError.CODE_TO_MESSAGE.default;
  }
}

LoaderError.CODE_TO_MESSAGE = MessageCodes;

export default LoaderError;
