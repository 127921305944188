import React from "react";
import classNames from "classnames";
import BootstrapButton from "react-bootstrap/Button";
import "./styles.scss";

const Button = ({ children, className, ...rest }) => (
  <BootstrapButton
    {...rest}
    size="sm"
    className={classNames("mydpd-btn", className)}
  >
    {children}
  </BootstrapButton>
);

Button.propTypes = BootstrapButton.propTypes;

export default Button;
