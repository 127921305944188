import React from "react";

import Loader from "./Loader";

/**
 *
 * @param load {Object|Function} - { loadFn, onLoadSuccess, onLoadFailure, Error}
 * @return {function(*): WithLoader}
 */
const withLoader = load => Component => {
  let options = load;
  if (typeof load === "function") {
    options = {
      loadFn: load,
    };
  }
  if (!options.hasOwnProperty("loadFn")) {
    throw new Error("loadFn must be provided!");
  }
  class WithLoader extends React.Component {
    render() {
      return (
        <Loader {...this.props} {...options}>
          <Component />
        </Loader>
      );
    }
  }
  return WithLoader;
};

export default withLoader;
