import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import "./styles.scss";

const Footer = ({ children, ...props }) => (
  <Card.Footer
    {...props}
    className={classNames("panel-footer", props.className)}
  >
    <Container fluid className="h-100">
      {children}
    </Container>
  </Card.Footer>
);

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Footer;
