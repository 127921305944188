import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FallBackImage from "../Image";

const ResponsiveImage = ({ alt, src }) => {
  const [dimensions, setDimensions] = React.useState({});

  const onLoadImage = React.useCallback(
    event => {
      setDimensions({
        width: event.target.naturalWidth,
        height: event.target.naturalHeight,
      });
    },
    [setDimensions]
  );

  const w100 = dimensions.width > dimensions.height;
  const h100 = dimensions.width < dimensions.height;
  return (
    <div className="image-helper">
      <FallBackImage
        fluid
        alt={alt}
        src={src}
        onLoad={onLoadImage}
        className={classNames("d-block", {
          "h-100 mx-auto": h100,
          "w-100 my-auto py-sm": w100,
        })}
      />
    </div>
  );
};

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default ResponsiveImage;
