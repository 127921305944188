import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

function OverlayBackdrop({ open, spinner }) {
  return <>{open && <div className="mydpd-backdrop">{spinner()}</div>}</>;
}

OverlayBackdrop.propTypes = {
  open: PropTypes.bool,
  spinner: PropTypes.func,
};

OverlayBackdrop.defaultProps = {
  open: false,
};

export default OverlayBackdrop;
