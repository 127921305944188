import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";
import "./styles.scss";

const ErrorAlert = ({
  title,
  error,
  eventId,
  reloadFn,
  onReportFeedbackClick,
}) => {
  const onClickHandler = React.useCallback(() => {
    if (eventId) {
      return onReportFeedbackClick(eventId);
    }
    return reloadFn();
  }, [eventId, onReportFeedbackClick, reloadFn]);

  return (
    <Error
      title={title}
      message={error.message}
      buttonText={eventId ? "Report Feedback" : "Retry"}
      onClickHandler={onClickHandler}
    />
  );
};

ErrorAlert.propTypes = {
  title: PropTypes.string,
  eventId: PropTypes.string,
  error: PropTypes.object,
  reloadFn: PropTypes.func,
  onReportFeedbackClick: PropTypes.func,
};

ErrorAlert.defaultProps = {
  onReportFeedbackClick: () => null,
};

export default ErrorAlert;
