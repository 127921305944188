import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import "./styles.scss";

const Header = ({ children, ...props }) => (
  <Card.Header
    {...props}
    className={classNames("panel-header", props.className)}
  >
    <Container fluid>{children}</Container>
  </Card.Header>
);

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Header;
