import * as React from "react";

const useBackdropController = () => {
  const [open, setOpen] = React.useState(false);

  const show = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const hide = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const showWhile = React.useCallback(
    fn => async (...args) => {
      setOpen(true);
      try {
        const result = await fn(...args);
        setOpen(false);

        return result;
      } catch (e) {
        setOpen(false);
        throw e;
      }
    },
    [setOpen]
  );

  return [open, show, hide, showWhile];
};

export default useBackdropController;
