import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";

import ImageNotFound from "../../../assets/image-not.jpg";
import "./styles.scss";

function FallBackImage({ src, fallBackSrc, className, ...props }) {
  const [config, setConfig] = React.useState({
    loaded: false,
    error: false,
  });
  return (
    <>
      {!config.error ? (
        <Image
          src={src}
          onLoad={event => {
            setConfig({
              loaded: true,
              error: false,
            });
            props.onLoad && props.onLoad(event);
          }}
          className={classNames("fallback-image", className)}
          onError={() => {
            setConfig({
              error: true,
              loaded: true,
            });
          }}
          {...props}
        />
      ) : (
        <Image
          src={fallBackSrc}
          className={classNames("fallback-image", className)}
          {...props}
        />
      )}
    </>
  );
}

FallBackImage.defaultProps = {
  fallBackSrc: ImageNotFound,
};

FallBackImage.propTypes = {
  src: PropTypes.string,
  fallBackSrc: PropTypes.string,
  className: PropTypes.string,
  ...Image.propTypes,
};

export default FallBackImage;
