import React from "react";
import PropTypes from "prop-types";

const Content = ({ Sidebar, children }) => (
  <>
    {Sidebar && <Sidebar />}
    <div className={"main" && !Sidebar && "main-full-width"}>{children}</div>
  </>
);

Content.propTypes = {
  Sidebar: PropTypes.elementType,
  children: PropTypes.node,
};

export default Content;
