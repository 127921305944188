import React from "react";
import ThemeContext from "./ThemeContext";

const withMyDpdTheme = Component => props => (
  <ThemeContext.Consumer>
    {context => <Component {...props} theme={context} />}
  </ThemeContext.Consumer>
);

export default withMyDpdTheme;
