import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Panel from "../Panel";
import Section from "../Section";
import "./styles.scss";

const Page = ({ title, children, footer, classNameBody, username }) => {
  return (
    <Panel className="page">
      <Panel.Header className="page-header">
        <Row className="align-items-center">
          <Col className="text-left">
            <span className="page-header-text">{title}</span>
          </Col>
          <Col className="text-right">
            <span className={classNames("text-uppercase", "page-header-text")}>
              {username}
            </span>
          </Col>
        </Row>
      </Panel.Header>
      <Panel.Body className={classNames("page-content", classNameBody)}>
        {children}
      </Panel.Body>
      {footer && <Panel.Footer className="page-footer">{footer}</Panel.Footer>}
    </Panel>
  );
};

Page.Section = Section;

Page.propTypes = {
  title: PropTypes.string.isRequired,
  username: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  classNameBody: PropTypes.string,
};

export default Page;
