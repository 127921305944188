import React from "react";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./styles.scss";

const Body = ({ children, ...props }) => (
  <Card.Body {...props} className={classNames("panel-body", props.className)}>
    {children}
  </Card.Body>
);

Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Body;
