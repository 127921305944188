import PropTypes from "prop-types";

import { THEME } from "./values";

export const ThemePropTypes = PropTypes.shape({
  themeName: PropTypes.oneOf(Object.values(THEME)),
  logoImg: PropTypes.any,
  spinner: PropTypes.any,
  dpdDomain: PropTypes.string,
  favicon: PropTypes.any,
  palette: PropTypes.shape({
    brand: PropTypes.string,
  }),
});
