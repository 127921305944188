export const SHIPPING = "Shipping";
export const COLLECTIONS = "Collections";
export const DELIVERIES = "Deliveries";
export const SHOP_RETURNS = "Shop Returns";
export const ADDRESS_BOOK = "Address Book";
export const DEPOT_FINDER = "Depot Finder";
export const MY_DPD = "MyDPD";
export const MY_DPD_LOCAL = "MyDPD Local";
export const HELP = "Help";
export const LOGOUT = "Logout";
export const HOME = "Home";
