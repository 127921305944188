import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Page from "../Page";
import "./styles.scss";
import Box from "../../../assets/sad-box.png";
import Button from "../Button";

const Error = ({ title, message, buttonText, onClickHandler }) => {
  const footer = (
    <Row className="h-100 align-items-center">
      <Col className="text-right">
        <Button onClick={onClickHandler}>{buttonText}</Button>
      </Col>
    </Row>
  );

  return (
    <Page title={title} classNameBody="mx-auto" footer={footer}>
      <Card className="error-container">
        <Card.Body className="text-center pt-5">
          <img src={Box} alt={message} />
        </Card.Body>
        <Card.Body>
          <Card.Text className={classNames("text-center", "text")}>
            {message}
          </Card.Text>
        </Card.Body>
      </Card>
    </Page>
  );
};

Error.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

Error.defaultProps = {
  title: "Error",
  onClickHandler: () => null,
};

export default Error;
