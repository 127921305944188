import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Panel from "../Panel";
import "./styles.scss";

const Section = ({ title, closable, children }) => {
  const [open, setOpen] = useState(true);

  return (
    <Panel className="section">
      {!!title && (
        <Panel.Header className="section-header">
          <Row className="align-items-center h-100">
            <Col className="h-100">
              <span className="section-header-title">{title}</span>
            </Col>
            {closable && (
              <i
                onClick={() => setOpen(!open)}
                className={classNames("collapsable-icon", open ? "up" : "down")}
              />
            )}
          </Row>
        </Panel.Header>
      )}
      {open && <Panel.Body className="section-content">{children}</Panel.Body>}
    </Panel>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  closable: PropTypes.bool,
};

export default Section;
