import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-bootstrap/Carousel";

import Modal from "../Modal";

import "./styles.scss";
import ResponsiveImage from "./ResponsiveImage";

const ImageView = ({
  show,
  images,
  onClose,
  onImageSelect,
  carouselProps,
  ...modalProps
}) => {
  return (
    <Modal centered={false} show={show} onHide={onClose} {...modalProps}>
      <div className="mydpd-image-view">
        <Modal.Header closeButton>Image view</Modal.Header>
        <Modal.Body>
          <Carousel
            interval={null}
            wrap={false}
            onSelect={onImageSelect}
            {...carouselProps}
          >
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <Carousel.Caption>
                  <h5>{image.title}</h5>
                </Carousel.Caption>
                <ResponsiveImage alt={image.title} src={image.src} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </div>
    </Modal>
  );
};

ImageView.defaultProps = {
  images: [],
};

ImageView.propTypes = {
  show: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
  ...Modal.propTypes,
  onImageSelect: PropTypes.func,
  carouselProps: Carousel.propTypes,
};

export default ImageView;
