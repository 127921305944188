import React from "react";
import useMyDpdTheme from "../Theme/useMyDpdTheme";

export default props => {
  const { dpdDomain } = useMyDpdTheme();
  const [years, setYears] = React.useState(2020);

  React.useEffect(() => {
    setYears(new Date().getFullYear());
  }, []);
  return (
    <a
      href={`${dpdDomain}/terms-and-conditions.jsp`}
      className="copyright"
      {...props}
    >
      Ⓒ {years} DPD. DPD is a trading division of DPDgroup. Registered office:
      PO BOX 6979, Roebuck Lane, Smethwick, West Midlands, B66 1BN. Registered
      in England and Wales No. 732993
    </a>
  );
};
