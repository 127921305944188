import React from "react";

import "./styles.scss";
import useMyDpdTheme from "../Theme/useMyDpdTheme";

const Spinner = () => {
  const { spinner } = useMyDpdTheme();
  return (
    <div className="mydpd-spinner">
      <img src={spinner} alt="Loading..." />
    </div>
  );
};

export default Spinner;
