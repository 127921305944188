import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Modal from "react-bootstrap/Modal";

import { VARIANT } from "../../constants/variant";
import "./styles.scss";

const MyDpdModal = props => (
  <Modal
    centered
    dialogClassName={classNames("mydpd-modal", props.dialogClassName)}
    {...props}
  >
    {props.children}
  </Modal>
);

const BG_COLOR_CLASS_NAME = {
  [VARIANT.BRAND]: "bg-brand",
  [VARIANT.INFO]: "bg-info",
  [VARIANT.DANGER]: "bg-danger",
  [VARIANT.WARNING]: "bg-warning",
};

const MyDpdModalHeader = ({ variant, children, ...headerProps }) => {
  return (
    <Modal.Header
      className={classNames(BG_COLOR_CLASS_NAME[variant])}
      {...headerProps}
    >
      {children}
    </Modal.Header>
  );
};

MyDpdModalHeader.defaultProps = {
  variant: VARIANT.BRAND,
};

MyDpdModalHeader.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  ...Modal.Header.propTypes,
};

MyDpdModal.Header = MyDpdModalHeader;
MyDpdModal.Body = Modal.Body;
MyDpdModal.Footer = Modal.Footer;

MyDpdModal.propTypes = Modal.propTypes;

export default MyDpdModal;
