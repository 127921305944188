import DPDLogo from "../../../assets/dpd-logo.png";
import DPDSpinner from "../../../assets/dpd-spinner.gif";
import DPDLocalSpinner from "../../../assets/dpdlocal-spinner.gif";
import DPDLocalLogo from "../../../assets/dpdlocal-logo.png";

export const THEME = {
  DPD: "dpd",
  DPD_LOCAL: "dpdlocal",
};

const getFaviconHref = host =>
  `https://www.${host}.co.uk/static/${host}/images/favicon.ico`;

export const ThemeValues = {
  [THEME.DPD]: {
    themeName: THEME.DPD,
    logoImg: DPDLogo,
    spinner: DPDSpinner,
    dpdDomain: process.env.REACT_APP_DPD_DOMAIN || "https://qa6-www.dpd.co.uk",
    favicon: getFaviconHref(THEME.DPD),
    palette: {
      brand: "#dc0032",
    },
  },
  [THEME.DPD_LOCAL]: {
    themeName: THEME.DPD_LOCAL,
    logoImg: DPDLocalLogo,
    spinner: DPDLocalSpinner,
    dpdDomain:
      process.env.REACT_APP_DPD_LOCAL_DOMAIN ||
      "https://qa6-www.dpdlocal.co.uk",
    favicon: getFaviconHref(THEME.DPD_LOCAL),
    palette: {
      brand: "#009adf",
    },
  },
};
