import React from "react";
import PropTypes from "prop-types";

import NavLink from "../NavLink";
import "./styles.scss";

const PrimaryMenu = ({ menuItems = [], activeIndex, onSelect }) => {
  return (
    <div className="nav-primary-menu">
      {menuItems.map(({ href, name, ...rest }, index) => (
        <NavLink
          key={href}
          href={href}
          name={name}
          className={index === activeIndex ? "active" : undefined}
          onClick={() => onSelect(index)}
        />
      ))}
    </div>
  );
};

PrimaryMenu.defaultProps = {
  activeIndex: 0,
  onSelect: () => null,
};

PrimaryMenu.propTypes = {
  menuItems: PropTypes.array,
  activeIndex: PropTypes.number,
  onSelect: PropTypes.func,
};

export default PrimaryMenu;
