import React from "react";
import PropTypes from "prop-types";
import { ThemeValues, THEME } from "./values";
import ThemeContext from "./ThemeContext";
import OverlayProvider from "../Overlay/Provider";
import PromptProvider from "../Prompt/Provider";

export const getDefaultTheme = () => {
  if (window.location.hostname.includes("dpdlocal")) {
    return THEME.DPD_LOCAL;
  }
  return THEME.DPD;
};

const favicon = href => {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = href;
  document.getElementsByTagName("head")[0].appendChild(link);
};

const DpdThemeProvider = props => {
  const theme = React.useMemo(() => ThemeValues[props.themeName], [
    props.themeName,
  ]);

  React.useEffect(() => {
    document.body.classList.add(`${theme.themeName}-theme`);
    favicon(theme.favicon);
  }, [theme]);

  return (
    <ThemeContext.Provider value={theme}>
      <OverlayProvider>
        <PromptProvider>{props.children}</PromptProvider>
      </OverlayProvider>
    </ThemeContext.Provider>
  );
};

DpdThemeProvider.defaultProps = {
  themeName: getDefaultTheme(),
};

DpdThemeProvider.propTypes = {
  themeName: PropTypes.oneOf(Object.values(THEME)),
  children: PropTypes.node,
};

export default DpdThemeProvider;
