import React from "react";
import PropTypes from "prop-types";

import useBackdropController from "./useBackdropController";
import BackdropOverlay from "./Backdrop";
import OverlayContext from "./Context";
import Spinner from "../Spinner";

const Provider = ({ children, spinner, position }) => {
  const [open, show, hide, showWhile] = useBackdropController();
  const contextValue = React.useMemo(
    () => ({
      show,
      hide,
      showWhile,
      position,
    }),
    [show, hide, showWhile, position]
  );

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      <BackdropOverlay open={open} spinner={spinner} />
    </OverlayContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  spinner: PropTypes.func,
  position: PropTypes.oneOf(["absolute", "fixed"]),
};

Provider.defaultProps = {
  // eslint-disable-next-line react/display-name
  spinner: () => <Spinner />,
};

export default Provider;
